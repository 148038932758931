@import "../../styles/abstracts/variables";

.stats__card{
    background: #FFF;
    border-radius: 10px;
    padding: 1.5rem 2rem;



    h4{
        padding: .4rem 0;
        font-size: .8rem;
        color: #333333;
        font-weight: 400;
    }

    h2{
        font-size: 1.2rem;
        font-weight: 700;
        font-family: Montserrat;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
       

        p{
            display: flex;
            align-items: center;
        }
    }
}

