@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.prompt {
  &__content {
    background: #fff;
    max-width: 100%;
    width: 650px;
    margin: 0 auto !important;
    border-radius: 10px;
    color: #000;
    padding: 4rem 1.5rem;
    z-index: 999;
    max-height: 80vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }

    span {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    &__detaills {
      h2 {
        font-family: "Cera Pro Bold";
        padding: 1rem 0;
        font-size: 1.2rem;
      }
      p {
        font-size: 0.9rem;
        line-height: 2;
        padding-bottom: 2rem;
      }
      
      &--btn {
        background: #fff;
        color: #000;
        padding: 0.8rem 0;
        border-radius: 10px;
        font-family: "Cera Pro Bold";
        cursor: pointer;
      }
    }
  }
}

form {
  width: 100%;
  label {
    display: block;
    color: #626262;
    font-weight: bold;
    font-size: 0.8rem;
    padding-bottom: 0.2rem;
  }
  input,
  textarea {
    width: 100%;
    height: 3rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    border: 1px solid #cdd2d8;
    padding: 0.5rem 1rem;
    color: #000;
    opacity: 1;
    background: transparent;
  }
  textarea {
    height: 12rem;
    padding: 1rem;
    line-height: 2;
  }
  button {
    background: #000;
    color: #fff;
    width: 100%;
    text-align: center;
    font-family: "Cera Pro Bold";
    padding: 1.2rem;
    font-size: 1rem;
    border-radius: 6px;
  }
}
