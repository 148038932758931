.edit__blog{

    &__textarea{
        width: 100%;
        border-radius: 6px;
        margin-bottom: 1rem;
        border: 1px solid #cdd2d8;
        height: auto;
        padding: 1rem;
        line-height: 1.3;
        background: transparent;
    }
    &__header{

        h2{
            font-family: "Clash Display", "Cera Pro Bold" !important;
            font-size: 2rem;
        }
        p{
            color: #333333;
            font-weight: bold;
            opacity: .8;
            padding-top: .5rem;
            font-size: .95rem;
            padding-bottom: 1rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid #ccc;
        }
    }

    form{
        width: 60%;
        label{
            display: block;
            color: #626262;
            font-weight: bold;
            font-size: .8rem;
            padding-bottom: .2rem;
        }
        input, textarea{
            width: 100%;
            height: 3rem;
            border-radius: 6px;
            margin-bottom: 1rem;
            border: 1px solid #cdd2d8;
            padding: .5rem 1rem;
            background: transparent;
        }
        textarea{
            height: 12rem;
            padding: 1rem;
            line-height: 2;
        }
        button{
            background: #000;
            color: #FFF;
            width: 100%;
            text-align: center;
            font-family: "Cera Pro Bold";
            padding: 1.2rem;
            font-size: 1rem;
            border-radius: 6px;
        }
    }
}