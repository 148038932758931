@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.adventures {
  h2 {
    font-family: "Clash Display", "Cera Pro Bold" !important;
    font-size: 2rem;
  }
  &__text {
    color: #333333;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0.5rem;
    font-size: 0.95rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #ccc;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
  }

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__action {
    height: 40px;
    width: 204px;
    border-radius: 30px;
    padding: 8px 24px 8px 24px;
    background: #000000;
    color: #fff;
    // font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}
