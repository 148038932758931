.card {
  width: 100%;
  height: 90%;
  background: #FFF;
  border-radius: 6px;

&__body{
  padding: .7rem 1rem;
  
  &__buttons{
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  & h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__level {
    color: #666666;
    font-weight: 400;
    opacity: 0.8;
    padding-top: 0.5rem;
    font-size: 0.95rem;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
  }

  &__btn{
    background: #000;
    padding: .8rem 1rem;
    border-radius: 10px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    font-size: .9rem;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    width: 100%;
  }

  &__btn--feature{
    @extend .card__body__btn;
    background-color: green;
  }

  &__view {
    display: flex;
    align-items: center;
    gap: 3px;
    color: #1579E4;
    font-weight: 700;
    cursor: pointer;
    transition: 0.5s;


    &:hover {
      color: blue
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
}
&__image {
  height: 227px;
  width: 100%;
  margin-bottom: 10px;  
  background-size: cover; /* Or 'contain' */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating */
  
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
}



.userProfileContainer{
  position: relative;
  
  .userProfileImg {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    width: 117px;
    height: 117px;

    background: black;
    font-size: 20px;
    font-weight: 800;
    color: white;
  }

  .userGameImg {
    position: absolute;
    top: 0;
    bottom: -20px;
  }
}



.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(57, 57, 57, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;

  .modalContainerContent {
    background-color: white;
    border-radius: 6px;
    padding: 27px;
    width: 612px;

    .closeIcon {
      color: #666666;
      font-size: 25px;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        color: black;
      }
    }

    .modalHeader {
      
      h2{
        margin-bottom: 0;
      }

      p{
        color: #B7B7B7;
        margin-bottom: 26px;
        
      }
    }


    .singleVersus{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .versusContent{
      display: flex;
      justify-content: space-between;
      align-items: center;


      h2 {
        font-size: 55px;
        font-family: Montserrat;
        color: #333333;
        font-weight: 600;
      }
    }

    .gameStatsContent{
      margin-top: 35px;

      display: grid;
      grid-template-columns: repeat(5, 1fr);

    
  
      @media (max-width: 600px) {
        grid-template-columns: repeat(3, 1fr); 
        gap: 20px;
      }

    }


  }
}



.gameStatsContainer {
  background: linear-gradient(169.21deg, #FFE1E1 9.94%, #FFFBD8 96.67%);
  border-radius: 5px;
  height: 186px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 11px;
  width: 101px;


  .gameStatsType {
    color: #353535;
    font-weight: 400;
    font-size: 14px;
    font-family: Montserrat;
  }


  .gameStatsValue {
    color: #353535;
    font-weight: 600;
    font-family: Montserrat;
  }

}
