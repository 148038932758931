

.editor {
  background: #F2F5F8;
  border-radius: 6px;
  // padding: .6em;
  border: 1px solid #F2F5F8;
  margin-bottom: 2rem;

  &:hover{
    background: #f2f5f85d;
  }


}


.ql-editor {
  background: red;
  min-height: 600px !important;
}