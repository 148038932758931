@import url('http://fonts.cdnfonts.com/css/clash-display');
@import '../../styles/abstracts/variables';

.product__hunt {
  font-family: 'Clash Display', 'Cera Pro Bold' !important;

  // padding: 2rem;

  header {
    padding-bottom: 2rem;

    h1 {
      color: #000000;
      font-size: 48px;
      font-weight: 700;
      line-height: 56px;

      @include mobile {
        color: #ffffff;
      }
    }

    p {
      color: #666666;
      font-size: 14px;
      line-height: 22px;

      @include mobile {
        color: #a9a7a7;
      }
    }
  }

  nav {
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-wrap: wrap;
    }

    .search__box {
      display: flex;
      align-items: center;
      border: 1px solid #dee5ed;
      border-radius: 6px;
      background-color: #ffffff;
      position: relative;
      width: 50%;

      @include tablet {
        width: 100%;
        margin-bottom: 0.7rem;
      }

      input {
        outline: none;
        padding: 10px;
        font-size: 14px;
        color: #666666;
        border: none;
        width: 80%;
        border-radius: 6px;
      }

      .search {
        background-color: #333333;
        font-size: 14px;
        color: #ffffff;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: grid;
        place-items: center;
        width: 20%;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: pointer;
      }
    }

    select {
      width: 20%;
      outline: none;
      border: 1px solid #dee5ed;
      border-radius: 6px;
      padding: 10px;

      @include tablet {
        width: 42%;
      }
    }

    .refresh,
    .export {
      width: 12%;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      border: 1px solid #dee5ed;
      background-color: #ffffff;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;

      @include tablet {
        width: 25%;
      }
    }
  }

  .flex__group {
    margin-top: 3rem;
    // display: flex;
    // gap: 1rem;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    .flex__box {
      background: #ffffff;
      padding: 24px;
      display: flex;
      flex-direction: column;

      .top__bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .status__pending,
        .status__active,
        .status__denied {
          display: flex;
          padding: 5px 13px;
          border-radius: 100px;
          gap: 7px;
          align-items: center;

          p {
            text-transform: capitalize;
          }
        }

        &__logo {
          width: 30px;
          height: 30px;
          overflow: hidden;
          border-radius: 50%;
        }

        .status__pending {
          background: #fff6e0;
          color: #f4b511;
        }

        .status__active {
          background: rgba(34, 187, 51, 0.08);
          color: #22bb33;
        }
        .status__denied {
          background: #e1e1e1;
          color: #141414;
        }
      }

      .description {
        font-size: 13px;
        color: #333333;
        margin-top: 1.2rem; 
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
         overflow: hidden;
        text-overflow: ellipsis;
      }

      .owner__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5rem;
        
        .by {
          flex: 1;


          h4 {
            font-weight: 600;
            font-size: 13px;
            color: #333333;
            flex: "1"
          }
        }

        .countries{
          display: flex;
          flex-direction: column;
          flex: 1;


          h5 {
            color: #666666;
            font-size: 13px;
            font-weight: 600;
          }



          .owner_countries {

            h5 {
              color: #666666;
              font-size: 13px;
            }
  

            display: flex;
            gap: 2px;
            flex-wrap: wrap;
            flex-direction: row;
            height: 30px;

            overflow: hidden;
            overflow-y: scroll;


            h4 {
              font-weight: 600;
              font-size: 13px;
              color: #333333;
            }
          }
        }

      }

      footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footer__icons {
          display: flex;
          gap: 10px;
          align-items: center;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .rotate {
    animation: rotate 4s linear infinite;
  }
}

.emptyContainer {
  margin-top: 50px;
  display: flex;
  color: #E01414;
  font-weight: bold;

  span {
    text-decoration: underline;
    color: #E01414;
    cursor: pointer;
    transition: 0.5s ease;

    &:hover {
      color: blue;  
    }
  }
}

.optionsContainer {
  position: relative;

  .modal {
    background-color: white;
    width: 232px;
    border-radius: 5px;
    position: absolute;
    margin-top: -20px;
    left: -200px;
    padding: 10px 20px;
    color: #333333;
    box-shadow: 0px 4px 30px 0px #0000001A;


    p {
      padding: 8px 0;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: 0.5s ease;

      &:hover {
        color: #000000;

      }
    }

    .denyProduct {
      color: #CC334F;

      &:hover {
        color: red;

      }
    }
  }
}



.product__details__container {

  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 14px;
    color: #333333;


    font-weight: 500;

    p {
      font-family: "Cera Pro";
      font-weight: 500;
    }


    &:hover {
      color:#A55BBF;
    }
  }
}


.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0;


  .productInfo{
    display: flex;
    align-items: center;
    gap: 25px;

    .productImage {
      width: 148px;
      height: 148px;
      border-radius: 50%;
      background-color: whitesmoke;
      box-shadow: 1px 1px 1px grey ;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    .productName {
      p {
        margin-bottom: 41px;
        color: #181818;
        font-weight: 700;
        font-size: 32px; 
        font-family: "Clash Display";
      }

      .social {
        display: flex;
        align-items: center;
        gap: 18px;
      }
    }
  }


  .buttonContainer {
    display: flex;
    gap: 41px;

    button {
      display: flex;
      align-items: center;
      gap: 6px;
      align-items: center;
      width: 236px;
      background-color: red;
      justify-content: center;
      height: 56px;
      border-radius: 10px;
      font-size: 14px;

    }

    button:nth-of-type(1) {
      background: #E01414;
      color: white;
      font-weight: 500;
      font-family: "Montserrat";
      transition: 0.5s ease;

      &:hover{
        background: red;
      }
    }
  
    button:nth-of-type(2) {
      background: #ECECEC;
      color: #E01414;
      font-weight: 700;
      font-family: "Clash Display";

      &:hover{
        opacity: 0.8;
      }

    }

  }



}

.productTabContainer {
  display: flex;
  gap: 122px;
    
  .productTabs{
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      height: 54px;
      // background: #333333;
      color: white;
      color: #333333;
      border-radius: 5px;
      width: 300px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-weight: 400; 
      font-family: "Cera Pro";


     
    }

    .active {
      background: #333333;
      color: white;
      font-weight: 700; 
      font-family: "Cera Pro";
    }
  }

  .buttonContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 26px;

    button {
      flex: 1;
      height: 40px;
      font-size: 14px;
      color: white;
      border-radius: 30px;
      font-weight: 500;
      font-family: "Montserrat";
      width: 100%;
      margin-top: 79px;
    }

    button:nth-of-type(1) {
      background: #22BB33;
      transition: 0.5s ease;

     
    }
  
    button:nth-of-type(2) {
      background: #ECECEC;background: #999999;
      font-family: "Montserrat";

    

    }
  }
}




.aboutContainer{
  width: 100%;

  h2 {
    font-family: Clash display;
  }  


  .aboutProduct {
    background: #F2F2F2;
    padding: 31px 15px;
    border-radius: 10px;
    margin-bottom: 20px;


  


    .supportCountries, .supportTags{
      font-weight: 700;
      font-family: "Cera Pro";
      margin-bottom: 11px;
    }

    .supportTags{
      margin-top: 26px;
    }

    .tagsContainer {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;

      p {
        border-radius: 10px;
        font-size: 14px;
        padding: 5px 16px;
        background: #E0E0E0;
        color: #3D3D3D;
      }
    }
  }
}

.avatarContainer{
  display: flex;
  flex-wrap: wrap;
  gap: 35px;

  .userContainer {
    margin-top: 16px;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    div{
      width: 130px;
      height: 130px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: red;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    p {
      font-family: Clash display;
      font-size: 14px;
      font-weight: 700;
    }
  } 
}


.stepsContainer {
  width: 100%;


  .stepsProduct {
    margin-bottom: 30px;


    h2 {
      font-family: Clash display;
      margin-bottom: 10px;
    }  
  
    .stepsBox {
      width: 100%;
      height: 560px;
      // background: #F2F2F2;
      border-radius: 10px;
      background: no-repeat;
      background-size: cover;
    }
  }

  
}

