.leader {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  font-size: 0.95rem;
  color: #333333;
  padding: 1.2rem 1rem;
  border-bottom: 1.17049px solid #dee6ed;
  font-weight: 500;
  padding-left: 50px;
}

// Media Queries
@media only screen and (max-width: 600px) {
  .leader {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    font-size: 0.9rem;
  }
}
