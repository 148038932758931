@import "@/styles/abstracts/variables.scss";
@import "@/styles/base/typography.scss";
@import "@/styles/abstracts/mixins";

.search {
  border: 1px solid #dee6ed;
  width: 80%;
  height: 3.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 toRem(20px);
  // height: toRem(56px);
  @include border-radius(8px);
  margin: toRem(50px) 0 toRem(20px) 0;
  padding-top: 1rem;

  & input {
    border: none;
    @include menu-txt;
    width: 90%;
  }
}
