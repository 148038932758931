@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.communities {
  h2 {
    font-family: "Clash Display", "Cera Pro Bold" !important;
    font-size: 2rem;
  }
  p {
    color: #333333;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0.5rem;
    font-size: 0.95rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #ccc;
  }

  &__tabs {
    padding-bottom: 1rem;
    display: flex;
    gap: 20px;
    margin-bottom: 3rem;

    &--users {
      background: green;
      padding: 0.8rem 1rem;
      border-radius: 6px;
      cursor: pointer;
      color: #fff;
      font-size: 0.85rem;
      font-weight: bold;
    }

    &--all {
      @extend .communities__tabs--users;
      background: #000;
    }

    &--communities {
      @extend .communities__tabs--users;
      background: #753ff5;
    }
    &--adventures {
      @extend .communities__tabs--users;
      background: #721c24;
    }
  }
}

.communities__table {
  &__container {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
}
