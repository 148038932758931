.communities__table {
  &__container {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
}
