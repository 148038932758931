@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.prompt {
  &__content {
    background: #fff;
    min-width: 200px;
    max-width: 650px;
    margin: 0 auto !important;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    z-index: 999;
    width: 100%;
    // text-align: center;

    h3 {
      font-family: "Cera Pro Bold";
      color: #000;
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
      border-bottom: 2px solid black;
    }

    h4 {
      font-family: "Cera Pro Bold";
      color: #000;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
    }

    &__detaills {
      &--user {
        padding-top: 0.5rem;
        margin-bottom: 2.5rem;
        display: flex;
        gap: 20px;

        h5 {
          color: #000;
          font-size: 1rem;
        }
        h6 {
          padding-top: 0.2rem;
          color: #666;
          font-size: 0.85rem;
        }

        &--img {
          max-width: 50px;
          border-radius: 50px;
        }
      }
      &__answer__success {
        margin: 10px auto;
        font-size: 1.2rem;
        padding: 10px 15px;
        border-radius: 10px;
        border: 2px solid green;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &__answer__error {
        margin: 10px auto;
        font-size: 1.2rem;
        padding: 10px 15px;
        border-radius: 10px;
        border: 2px solid red;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
