@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.prompt {
  min-width: 300px;
  &__content {
    h4 {
      font-family: "Clash Display", "Cera Pro Bold" !important;
      font-size: 1.2rem;
      color: #000;
      padding-bottom: 2rem;
    }

    span {
      position: absolute;
      right: 20px;
      top: 25px;
      cursor: pointer;
    }

    &__detaills {
      select {
        width: 100%;
        border: 1px solid #ced1d6;
        border-radius: 10px;
        padding: 15px 20px;
        outline: none;
        color: #000;
        box-shadow: none;
        font-size: 1.2rem;
        font-weight: bold;
        font-family: "Clash Display", "Cera Pro Bolder" !important;
        margin-bottom: 1.5rem;
      }
      &--input {
        background: #fff;
        border: 1px solid #ced1d6;
        border-radius: 10px;
        padding: 1rem 1.5rem;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;

        input {
          border: none;
          color: #000;
          box-shadow: none;
          font-size: 1rem;
          width: 100%;
          @media only screen and (max-width: 600px) {
            width: 100%;
          }
        }

        p {
          border-bottom: 0;
          padding-bottom: 0;
        }
        &__network {
          font-size: 1rem;
          font-family: "Cera Pro Bold";
          @media only screen and (max-width: 600px) {
            font-size: 0.8rem !important;
          }
        }
        h3 {
          font-size: 1rem;
          font-family: "Cera Pro Bold";
        }
      }

      &--conversions {
        div {
          display: flex;
          justify-content: space-between;
          p {
            border-bottom: 0;
          }
        }
      }
      &--btn {
        background: #808183;
        color: #fff;
        padding: 1rem 2rem;
        border-radius: 10px;
        font-family: "Cera Pro Bold";
        cursor: pointer;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      &--success {
        @extend .prompt__content__detaills--btn;
        background: rgb(12, 163, 12);
        transition: all 0.3s ease-in-out;
        &:hover {
          background: rgb(3, 119, 3);
        }
      }
    }
  }
}
