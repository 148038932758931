@import "@/styles/abstracts/variables.scss";
@import "@/styles/base/typography.scss";
@import "@/styles/abstracts/mixins";

.sign-in {
  height: 100vh;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: $col-white;

  @include respond(big-tab) {
    grid-template-columns: 1fr;
  }

  @include respond(phone) {
    height: 100vh;
  }

  &__top-logo {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
  &__bottom-logo {
    position: absolute;
    bottom: 0;
    left: toRem(172px);
  }
  &__sign-up {
    @include row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    right: toRem(100px);
    &-container {
      @include grid-column(2, auto);
      grid-column-gap: toRem(50px);
    }
    &-carousel {
      width: toRem(410px);
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__content {
    padding: toRem(100px);
    height: toRem(550px);

    @include respond(max-laptop) {
      padding: toRem(70px);
    }

    @include respond(mid-laptop) {
      padding: toRem(35px);
    }

    @include respond(big-tab) {
      padding: toRem(400px) toRem(40px);
    }
    @include respond(phone) {
      padding: toRem(150px) toRem(20px);
    }

    &-container {
      display: grid;
      grid-template-rows: auto 1fr;
      grid-row-gap: 40px;
      height: 100%;
    }

    &-header {
      &-container {
        @include flex-space-between;
        & h3 {
          color: $col-primary;
          // @include h4;
        }
      }

      &-title {
        // @include body-two;
        color: $col-grey;
        margin-top: toRem(4px);
      }

      &-button {
        // @include body-one;
        @include row;
        justify-content: center;
        color: $col-primary;

        & a {
          font-weight: 600 !important;
          color: $col-primary;
          // @include body-one;
          margin-left: toRem(5px);
        }
      }

      &-forgot-password {
        // @include body-three-2;
        color: $col-primary;
        padding-left: toRem(23px);
        margin-top: toRem(30px);
      }
    }
  }

  &__form {
    @include grid-row(2, auto);
    width: 100%;

    &-input {
      @include grid-row(auto-fill, 55px);
      grid-row-gap: toRem(50px);
    }
    &-button {
      margin-top: toRem(40px);
      @include grid-row(2, auto);
      grid-row-gap: toRem(20px);
    }
  }
}
