@import "../../styles/abstracts/variables";

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  text-align: center;
  padding-right: 3.5rem !important;

  &__loading-spinner {
    width: 50px;
    height: 50px;
    border: 7px solid #f3f3f3; /* Light grey */
    border-top: 7px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}