@import url('http://fonts.cdnfonts.com/css/clash-display');
@import '../../styles/abstracts/variables';



.games__container{
  font-family: 'Clash Display', 'Cera Pro Bold' !important;
  

  &__card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    margin-top: 15px;

   


  }

  &__available{
    margin-top: 35px;

    h2 {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 15px;
      font-family: 'Clash Display', 'Cera Pro Bold' !important;
    }
  }



  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr); // Two items in a row for medium screens
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr; // One item in a row for small screens
    }
  }
}

