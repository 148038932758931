@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.alert{
    position: fixed;
    right: 3%;
    top: 2%;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    z-index: 99999;
    p{
        color: #fff;
        text-align: left
    }
}

.success{
    @extend .alert;
    background: #31A745;

    &__helper{
        background-color: #D4EDDA;
        padding: .3rem .8rem;
        border-radius: 8px;
        font-size: .9rem;
        margin-right: .5rem;
        font-family: "Cera Pro Bold";
        color: #31A745;
    }
}
.error{
    @extend .alert;
    background: #721C24;

    &__helper{
        background-color: #F8D7DA;
        padding: .3rem .8rem;
        border-radius: 8px;
        font-size: .9rem;
        margin-right: .5rem;
        font-family: "Cera Pro Bold";
        color: #721C24;
    }
}

//Media Queries 
@media only screen and (max-width: 600px) {
    .alert{
        top: 40%;
        right: 2%;
        left: 2%;
        justify-content: flex-start;
        gap: 20px
    }

    .success, .error{
        @extend .alert;
        &__helper{
            padding: .4rem 1rem;
            margin-right: .1rem !important;
        }
    }
}


// //Media Queries for phone
// @include phone {
//    .alert{
//         right: 0;
//         max-width: 100vw;
//         left: 0;
//         top: 0;
//         border-radius: 0;
//    }
// }