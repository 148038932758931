@import url('http://fonts.cdnfonts.com/css/clash-display');
@import "../../styles/abstracts/variables";

.users{
    h2{
        font-family: "Clash Display", "Cera Pro Bold" !important;
        font-size: 2rem;
    }
    p{
        color: #333333;
        font-weight: bold;
        opacity: .8;
        padding-top: .5rem;
        font-size: .95rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;
    }
}