@import "../../styles/base/typography";

.users__wrapper {
  // padding-top: 2rem;

  &__pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    margin-top: 1rem;

    &__button {
      min-width: 100px;
      padding: 0.5rem;
      border-radius: 20px;
      background: #a4adc7;
      color: rgb(11, 15, 19);
      cursor: pointer;
      margin: 0.5rem;
      transition: all 0.2s ease-in-out;
      outline: none;
      border: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #859cd7;
        color: rgb(3, 5, 26);
      }
    }
  }

  &__container {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  &__grid {
    background: #666;
    padding: 1rem;
    color: #fff;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: grid;
    width: fit-content;
    grid-template-columns: 280px 280px 150px 140px 150px 150px;
    gap: 15px;
    div {
      font-family: "Cera Pro Bold";
    }
  }
}
