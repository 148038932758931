// @import "@/styles/abstracts/variables.scss";
// @import "@/styles/base/typography.scss";
// @import "@/styles/abstracts/mixins";

.details__container {
  .details__content {
    &__header {
      font-size: 2rem;
    }
  }
}

.level_select {
  display: block;
  width: 100%;
  height: 55px;
  margin-top: 0.3rem;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 5px 10px;
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 22px;
  color: #333333;
}

.create__adventure {
  &__content {
    max-width: 800px;

    &__container {
      margin-bottom: 1.5rem;
    }

    &__img {
      background: #d9d9d9;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      cursor: pointer;
      position: relative;

      img {
        border-radius: 50%;
      }

      &__edit {
        position: absolute;
        background-color: #333333;
        border-radius: 50%;
        bottom: 0;
        height: 35px;
        display: flex;
        justify-content: center;
        width: 35px;
        align-items: center;
        right: -12px;
        color: white;
        font-size: 1rem;
      }
    }

    &__inputs {
      border-bottom: 1px solid #dee6ed;
      padding-bottom: 1rem;
      input[type="file"] {
        display: none;
      }

      label {
        font-weight: bold;
        color: #333333;
        padding-bottom: 0.5rem;
      }
    }

    h4 {
      font-family: "Cera Pro Bolder";
      color: #333333;
      padding-bottom: 2rem;
    }
    h6 {
      padding-bottom: 2rem;
      color: #333333;
      font-family: "Cera Pro Bold";
    }
  }
}

// Media Queries
@media only screen and (max-width: 600px) {
  .create__adventure {
    &__content {
      // color: #FFF;
      h4,
      h6 {
        // color: #FFF;
      }
      &__inputs {
        border-bottom: 1px solid #fff;
        label {
          // color: #FFF;
          font-size: 0.8rem;
        }
      }
    }
  }
}
