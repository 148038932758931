@import url('http://fonts.cdnfonts.com/css/clash-display');
@import "../../styles/abstracts/variables";

.prompt{
    background: rgba(0,0,0,.55);
    position: fixed;
    height: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 999;

    &__content{
        background: #000;
        position: absolute;
        top: 30%;
        left: 37%;
        max-width: 450px;
        margin: 0 auto !important;
        border-radius: 10px;
        color: #FFF;
        padding: 4rem 1.5rem;
        z-index: 999;
        text-align: center;

        span{
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }

        &__detaills{
            h2{
                font-family: "Cera Pro Bold";
                padding: 1rem 0;
                font-size: 1.2rem;
            }
            p{
                font-size: .9rem;
                line-height: 2;
                padding-bottom: 2rem;
            }
            &--btn{
                background: #fff;
                color: #000;
                padding: .8rem 0;
                border-radius: 10px;
                font-family: "Cera Pro Bold";
                cursor: pointer;
            }
        }
    }
}
