@import url('http://fonts.cdnfonts.com/css/clash-display');
@import "../../styles/abstracts/variables";

.blogs{
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__create{
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000;
            color: #fff;
            border-radius: 50px;
            padding: .7rem 1.5rem;
            font-weight: bold;
            font-size: .9rem;
            cursor: pointer;
        }
    }
        h2{
            font-family: "Clash Display", "Cera Pro Bold" !important;
            font-size: 2rem;
        }
        p{
            color: #333333;
            font-weight: bold;
            opacity: .8;
            padding-top: .5rem;
            font-size: .95rem;
            padding-bottom: 1rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid #ccc;
        }
}