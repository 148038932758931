.stats__card__wrapper{
   &--content{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
   }

   &__tabs{
      padding-bottom: 1rem;
      display: flex;
      gap: 20px;
      margin-bottom: 1rem;

      &--users{
         background: #000;
         padding: .8rem 1rem;
         border-radius: 6px;
         cursor: pointer;
         color: #FFF;
         font-size: .85rem;
         font-weight: bold
      }

      &--communities{
         @extend .stats__card__wrapper__tabs--users;
         background: #753FF5;
      }
      &--adventures{
         @extend .stats__card__wrapper__tabs--users;
         background: #FF6B1B;
      }
   }
}