// @import "/styles/abstracts/mixins";
// @import "/styles/abstracts/variables";

.dashboard__layout{
    height: 100%;
    width: 100%;
    // position: relative;

    &__content{
        display: grid;
        grid-template-columns: 270px 1fr;
        grid-gap: 20px;
        height: 100vh;

        &--sidebar{
            height: 100vh;
            width: 100%;
            position: sticky;
            overflow: hidden;
            top: 0;
            left: 0;
        }
        &--children{
            height: 100vh;
            overflow-y: scroll;
            padding-top: 7rem;
            padding-bottom: 8rem;
            padding-left: 1rem;
            padding-right: 2rem;
            position: relative;
            // background: red;
            // color: #FFF !important;
            

            &--btn{
                position: absolute;
                right: 12px;
                top: 35px;
                z-index: 999;
            }
            &::-webkit-scrollbar{
                display: none;
            }
        }
    }
}

.quick__actions{
    // background: #000;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: none;
}

// Meida Queries 
@media only screen and (max-width: 600px){
    .dashboard__layout{
        background: #141414;
        right: 0;
        position: relative;
        width: 100vw !important;

        // &--bg{
        //     position: absolute;
        //     top: 15%;
        //     right: 10%;
        //     z-index: 999;
        //     transform: rotate(222deg)
        // }
        &__content{
            grid-gap: 0px !important;
            &--sidebar{
                display: none;
            }

            &--children{
                padding-top: 2.5rem;
                padding-left: .5rem;
                padding-right: 1.2rem;
                width: 100vw;
                right: 0;
                overflow-x: hidden;
                background: #141414;
            }
        }
    }

    // Show Actions 
    .quick__actions{
        display: block;
        background: #f2f5f8;
    }
}