@import "@/styles/abstracts/variables.scss";
@import "@/styles/base/typography.scss";
@import "@/styles/abstracts/mixins";

.module {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-bottom: 5rem;

  &__info_tab {
    background-color: #ecd771;
    border: 2px solid #e1bf16;
    color: #1c1b1b;
    margin: 0.8rem auto;
    padding: 8px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    row-gap: 5px;
  }

  &__module_tab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;
    width: 100%;
    background: #f1f1f1;
    border-radius: 5px;
    margin-top: 0.7rem;

    &__title {
      font-family: "Cera Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 22px;
      color: #333333;
    }
  }

  &__module_display {
    width: 100%;
    border: 1.2px solid #cccccc;
    border-radius: 5px;
    margin-top: 10px;
    padding: 0.7rem;
    &__under_tab {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      padding: 10px 12px;
      gap: 10px;
      width: 100%;
      margin-bottom: 0.7rem;

      p {
        cursor: pointer;
      }
    }

    &__input_container {
      margin-top: 0.8rem;
      margin-bottom: 1rem;

      label {
        font-family: "Cera Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
    }

    &_input,
    &_select {
      height: 45px;
    }

    &_input,
    &_text_area,
    &_select {
      // position: absolute;
      display: block;
      width: 100%;
      margin-top: 0.3rem;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 10px;
      padding: 5px 10px;
      font-family: "Cera Pro";
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 22px;
      color: #333333;
    }

    &_text_area {
      resize: none;
    }
  }

  &__another_option {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #1579e4;
    cursor: pointer;
    font-size: 0.9rem;
  }

  &__add__another {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    color: #1579e4;
    cursor: pointer;
    font-size: 0.9rem;
  }

  &__remove_option {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: red;
    cursor: pointer;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  &__button {
    width: 100%;
    padding: 15px;
    margin: 0.8rem auto;
    background-color: #333333;
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;

    &:hover {
      background-color: #1c1b1b;
    }
  }

  &__line {
    background: #b76100;
    border: 2px solid #b76100;
    margin: 1rem auto;
    border-radius: 20px;
  }
}

.quiz__option {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
  margin-bottom: 2rem;

  label {
    margin: 0 !important;
  }
}
