$animation-duration: 22s; // Animation duration


@keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}



@keyframes moveDown {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}


@keyframes moveUp {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 2));
  }
}

.discoverContainer {
  height: 100vh;
  width: 100%;
  color: black;
  padding-bottom: 200px;
  padding-top: 148px;
  



  .topProductContainer {
    background-color: #000000;
     
    height: 825px;
    overflow-y: hidden;

    @media screen and (max-width: 768px) {
      height: 440px;
      width: 100%;
      overflow-x: hidden;
    }

    .topContainer{
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      gap: 46px;
    }



    .topLeftContainer {
      color: white;

      .topContent{
        margin-top: 88px;
      }

      .topContentTwo {
        display: flex;
        align-items: center;
        gap: 80px;
        margin: 33px 0;
      }

      .item {
        display: flex !important;
        align-items: center !important;
        gap: 12px;

      }


      h1 {
        width: 70%;
        text-transform: uppercase;
        font-size: 2.3rem;
        margin-top: 14px;
        line-height: 54px;
        font-weight: 700;
        margin-top: 35px;
        font-family: "Clash Display", "Cera Pro Bolder" !important;

        span {
          background: linear-gradient(180deg, #FF8BF7 0%, #00AAFF 100%, #00AAFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          img {
            display: inline-block;
            width: 20px;
            margin-bottom: 10px;
            margin-left: 10px;
          }
        }
      }




      .description {
        font-size: 16px;
        width: 80%;
        font-family: "Cera Pro Bold";
        font-weight: 500;
        line-height: 28px;
        margin-top: 20px;
      }

      button {
        background-color: #00AAFF;
        color: white;
        margin-top: 40px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 0.8rem 2rem;
        color: #fff;
        font-weight: bold;
        align-items: center;
        clip-path: polygon(93% 0, 100% 50%, 94% 97%, 8% 100%, 0% 50%, 10% 0);
        width: 250px;
        height: 56px;
        font-size: 0.8rem;
        transition: 0.5s ease;
        
        &:hover {
          transform: translateY(-1px);
          box-shadow: inset 160px 0 0 0 #7c54d158;
        }

      }


      @media screen and (max-width: 768px) {
       

        .topContent {
          margin-top: 20px;
        }

        h1 {
          font-size: 1rem;
          line-height: 25px;
          width: 80%;

          span {
            img {
              width: 10px;
            }
          }
        }

        p {
          font-size: 0.6rem;
          // display: none;
        }

        .description {
          display: none;
        }

        button {
          width: fit-content;
          cursor: pointer;
        }
      }
    }

    .topProductImageContainer {
      display: flex;
      gap: 19px;
      width: 100%;

      background:  black url("https://res.cloudinary.com/dj0rcdagd/image/upload/v1682447110/bgss_eg23cz.svg");
      background-repeat: no-repeat;
      padding: 0 15px;


      div {
        // position: absolute;
        width: 100%;
        animation-duration: $animation-duration;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      div:first-child {
        top: 0;
        animation-name: moveUp;
      }

      div:last-child {
        bottom: 0;
        animation-name: moveDown;
      }
}

  }
  
  .productImageContainer {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 149px;

    img {
      object-fit: cover;
      object-position: center;
      display: block;
      max-width: 100%;
      height: auto
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }



  .trendingProductWeek {
    background-color: #181818;
    width: 100%;
    color: white;
    padding: 37px 0 90px;

    .trendingProductTitleContainer {

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin-bottom: 45px;

    
    h1{
        font-family: "Clash Display", "Cera Pro Bolder" !important;
        font-size: 1.8rem;
        font-weight: bolder;
        text-align: center;
        color: white;


        img {
          width: 20px;
          display: inline-block;
          margin-bottom: 10px;
          margin-left: 10px ;
        }


        @media screen and (max-width: 1300px) {
            font-size: 1.9rem;
        }

        @media screen and (max-width: 650px) {
            font-size: 1rem;

            img {
              margin-bottom: 5px;
              width: 15px;
            }

        }
    }

    

    }

    .productCardContainer {
      display: flex;
      gap: 84px;

      @media screen and (max-width: 1300px) {
          flex-direction: column;
          gap: 50px;
        }

        @media screen and (max-width: 650px) {
          
        }
      }
  }
}


.productCard{
  margin-top: 20px;

  p {
      margin-top: 10px;
      line-height: 28px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 35px;
      font-family: "Cera Pro Bold";
    }

  .cardHeader{
    display: flex;
    justify-content: space-between;

    
    .cardTop{
      display: flex;
      gap: 20px;


         h2 {
          font-size: 1.3rem;
          margin-top: 14px;
          font-weight: 700;
          font-family: "Clash Display", "Cera Pro Bolder" !important;
        }

        p {
          margin-top: 10px;
          color: #181818;
          width: 90%;
        }


        .content {
          width: 100%;
        }

        .cardImage{
          width: 120px;
          height: 120px;
          padding: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          border-radius: 50%;
          overflow: hidden;

          // animation: rotateAnimation 12s infinite;
          // animation-timing-function: ease-in-out;

          img{
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
            border-radius: 50%;
          }

          @media screen and (max-width: 650px) {
            width: 50px;
            height: 50px;
          }
        }

    }

    .downloadBtnContainer{
      display: flex;
      gap: 17px;

      button {
        width: 130px;
        transition: 0.5s ease;

        // &:hover {
        //   transform: translateY(-2px);
        // }
      }
    }

    @media screen and (max-width: 968px) {
      gap: 16px;
      flex-direction: column;


      .cardTop {
        gap: 13px;
        h2 {
          font-size: 1.1rem;
        }

        p {
          width: 100%;
          font-size: 14px;
          font-family: "Cera Pro";
          line-height: 22px;
        }
      }

      .downloadBtnContainer {
        justify-content: center;
      }
    }
  }


  .cardBody{
    p {
      color: #686868;
      margin-top: 35px;
    }
  }


}



.topQuestion {
  color: #E01414;
  display: flex;
  justify-content: center;
  background: #F2F2F2;
  padding: 10px 0;

  p {

    font-family: "Cera Pro Bold";
    text-align: center;
  }

   
  @media screen and (max-width: 1068px) {
    p{
      font-size: 12px;
    }
  }
}

.report {
  margin-top: 98px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  color: #E01414;

  p {
    font-family: 1.3rem;
    font-family: "Cera Pro";
    font-weight: 550;
  }

  
  @media screen and (max-width: 1068px) {
    margin-top: 30px;
  }

}

.productNameContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;

  p {
    font-family: 1.3rem;
    font-family: "Cera Pro";
    font-weight: 700;
  }

  




  .productLeft {
    
    width: 45%;


    .leftTopContainer {
      .leftImage {
        margin-bottom: 36px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
        }

      }

      .mobileImage{
        display: none;
      }

      .leftDescriptionContainer {
        
        div {
          display: flex;
          align-items: center;
          gap: 18px;
        }

        .top {
          margin-bottom: 19px;

          .topDesktop{
            // font-family: ;
        
            font-weight: bold;
            font-family: "PP Pangaia Medium" !important;
            font-family: "Exo", sans-serif;
          }
        }
      }
      }
    }

    .productRight{
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .btnContainer{
        display: flex;
        gap: 41px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 230px;
          height: 56px;
          transition: 0.5s ease;
          font-family: Clash Display;
          font-weight: 700;
          gap: 6px;
        }
        
        button:first-child {
          background-color: #E01414;
          color: white;
          border-radius: 6px;
          background: linear-gradient(180deg, #FF0835 0%, #960822 100%);
          border-width: 1px, 1px, 0px, 1px;
          border-style: solid;
          border-color: #EB7777;
          padding: 1rem 2rem;
          color: #fff;
          font-weight: bold;
          align-items: center;
          
          font-size: 0.9rem;
          text-transform: capitalize;
          
          &:hover {
            transform: translateY(-1px);
            box-shadow: inset 160px 0 0 0 red;
          }
        }

        button:last-child {
          background-color: #ECECEC;
          color: #E01414;
          border-radius: 10px;
          width: 216px;

          &:hover {
            transform: translateY(-1px);
            box-shadow: inset -160px 0 0 0 whitesmoke;
          }
        }
      }

      .link {
        width: 100%;
        margin-top: 60px;

        .linkTitle {
          color: #464646;
          font-weight: 700;
          font-family: "Montserrat";

        }

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #F2F2F2;
          margin-top: 10px;
          padding: 16px 21px;
          border-radius: 10px;

          .copy {
            color: #E01414;
            font-family: Clash Display;
            font-weight: 700;
            cursor: pointer;
            transition: 0.5s ease;

            &:hover {
              color: red;
            }
          }
          
        }
      }
    }


    @media screen and (max-width: 1068px) {
       p {
          font-size: 0.9rem;
          font-family: "Cera Pro";
          font-weight: 700;
        }


      flex-direction: column;

      .productLeft, .productRight {
        width: 100%;
      }

      .productLeft {

        .leftTopContainer {
          .leftImage, .topDesktop{
            display: none;
            
          }

          .mobileImage{
            display:flex;
            align-items: center;
            gap: 14px;
            margin-bottom: 20px;

            img {
              width: 56px;
              height: 56px;
              border-radius: 50px;
            }

            .productTitle{
              // font-family: ;
          
              font-weight: bold;
              font-family: "PP Pangaia Medium" !important;
            }
          }

          .leftDescriptionContainer {
            display: flex;
            gap: 24px;
            flex-direction: column-reverse;
          }
        }
       
      }

      .productRight {
        margin-top: 38px;
        align-items: flex-start;
      }
    }

     @media screen and (max-width: 468px) {
      .productRight {
        margin-top: 38px;
        align-items: flex-start;

        .btnContainer{
          gap: 20px;

          button {
            font-size: 12px;
            width: 160px;
            padding: 0;
            font-weight: 500;
          }

          button:first-child {
            padding: 0;
            font-weight: 500;
          }

          button:last-child {
            width: 160px;
          }
        }

        .link {

          div {
            p:first-child {
              width: 200px; /* Set the desired width for the container */
              white-space: nowrap; /* Prevent text from wrapping to the next line */
              overflow: hidden; /* Hide any content that exceeds the container's width */
              text-overflow: ellipsis; 
            }
            
          }
        }
      }
    }

    @media screen and (max-width: 328px) {
      
      .productRight {

        .btnContainer{
          flex-direction: column;
          margin: 0 auto;

          gap: 25px;

          button {
            font-size: 12px;
            width: 180px;
            padding: 0;
            font-weight: 500;
          }
        }
      }
    }
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  margin: 36px 0;
  border-radius: 10px;
  border: 1px solid grey;
  transition: background-position 5s ease-in-out; 
  // animation: animateBackground 2s linear infinite; 

  @media screen and (max-width: 768px) {
    height: fit-content;
  }

  img {
    width: 100%;
    height: 100%; /* Add this to maintain aspect ratio */
    object-fit: cover; /* Prevent stretching, maintain aspect ratio */
  }
}


@keyframes wavingFlag {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}

.flag {
  animation: wavingFlag 2s infinite;
}

.moreDescriptionContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  
  
  .about{
    background-color: #F2F2F2;
    flex: 1;
    padding: 30px;
    border-radius: 10px;

    div {
      display: flex;
      gap:22px;

      p {
        font-weight: 600;
      }
    }

    p {
      font-size: 16px;
    }
    .aboutText {
      margin: 11px 0 ;
      line-height: 28px;
    }
    .readMore {
      font-weight: 900;
      font-family: "Cera Pro";
    }
  }

  .tagsContainer {
    padding: 30px;
    border-radius: 10px;
    flex: 0.5;
    background-color: #F2F2F2;

    p {
      font-weight: 700;
      font-family: "Cera Pro";


      color: #464646;
      font-weight: 700;
      font-family: "Montserrat";

    }

    .tags {
      margin-bottom: 31px;
    }

    .tagContent{
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      gap: 20px;
      margin-top: 10px;

      span {
        background-color: #E0E0E0;
        display: flex;
        gap: 10px;
        padding: 5px 16px;
        border-radius: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: shake 24s ease-in-out infinite;

        img {
          width: 18px;
          height: 18px;
          border-radius: 50px;
          overflow: hidden;
        }
      }
    }
  }

  
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

  @media screen and (max-width: 968px) {
    flex-direction: column;

    .about {
      padding: 29px 17px;

      .head {
        font-size: 16px;
      }

      div {
        justify-content: space-between;
      }
      p{
        font-size: 14px;
      }
    }

    .tagsContainer{
      padding: 29px 17px;
    }
  }

}



@keyframes animateBackground {
  0% {
    background-position: center;
  }
  25% {
    background-position: top right;
  }
  50% {
    background-position: bottom center;
  }
  75% {
    background-position: bottom left;
  }
  100% {
    background-position: center;
  }
}


@keyframes moveArrow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-3px);
  }
}











.team {
  margin: 70px 0 100px;
  
  h1 {
    text-align: center;
  }

  .teamContainer{
    display: flex;
    overflow-x: hidden;
    gap: 50px;
    margin-bottom: 100px;
    

    .teamImage {
      margin-top: 45px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }

      div{ 
        width: 154px;
        height: 154px;
        border-radius: 50%;
        overflow: hidden;
        
        img {
          width: 100%;
          height: 100%;
          transition: 0.5s ease;

          &:hover {
            transform: translateY(-15px);
            box-shadow: 0 10px 100px black;
            opacity: 0.9;
          }

          @media screen and (max-width: 968px){
             &:hover {
              transform: translateY(0px);
              box-shadow: none;
              animation-duration: $animation-duration;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              animation-name: moveUp;
            }

          }
          
        }
      }

      p {
        font-family: "Clash Display", "Cera Pro Bolder" !important;
        font-size: 1.1rem;
        font-weight: 700;
      }

      @media screen and (max-width: 968px) {

        animation-duration: $animation-duration;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: moveUp;
        

        div {
          width: 100px;
          height: 100px;
        }

        p {
          font-size: 0.8rem;
        }
      }

      
    }
  }

  
}



.details{
  padding: 28px 0 130px;


  .detailsContainer{

     h1 {
      font-family: "Clash Display", "Cera Pro Bolder" !important;
      font-size: 1.3rem;
      font-weight: 700;
      text-transform: capitalize;
    }

    .back {
      display: flex;
      align-items: center;
      // gap: 5px;
      cursor: pointer;

      &:hover .arrow {
        color: red;
      }

      .arrow {
        transition: transform 0.3s ease;
        animation: moveArrow 2s infinite;
      }
      
      p {
        font-size: 1.2rem;
        color: #181818;
        font-family: "Cera Pro Bold";
        transition: transform 0.3s ease;
      }

      &:hover p {
        color: red;
      }
    }

    .statisticsContainer{
      margin: 200px 0 16px;
    }

    .data {
      margin-top: 66px;
      display: flex;
      justify-content: center;
      gap: 100px;

      div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        p {
          color: #686868;
          font-family: "Cera Pro Bold";
          font-weight: 500;
        }
      }

      div:nth-child(2){
        border-left: 1px solid #D8D8D8;
        border-right: 1px solid #D8D8D8;
        padding: 0 128px;
      }

      @media screen and (max-width: 968px) {
        margin-top: 40px;
        gap: 60px;
        flex-direction: column;
        

        div:nth-child(2){
          border-left: none;
          border-right: none;
          padding: 0 0px;
        }

      }
    }

  }
}


@keyframes rotateAnimation {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}


@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
    box-shadow: none;
  }
  20% {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px black;
  }
  50% {
    transform: translateY(0);
    box-shadow: none;
  }
  80% {
    transform: translateY(-5px);
    box-shadow: 0 2px 4px black;
  }
  100% {
    transform: translateY(0);
    box-shadow: none;
  }
}


.commentContainer {
  display: flex;
  position: relative;
  margin-bottom: 78px;
  margin-top: 30px;


  .inputContainer {
    flex: 1;
    display: flex;
    gap: 20px;

    img {
      position: absolute;
      width: 35px;
      left: 20px;
    }
  
    input {
      flex: 1;
      border: 1px solid #BFBFBF;
      padding-left: 16px;
      border-radius: 10px;
      padding: 1rem 2rem;
      padding-left: 4rem;
    }
    
  }

  .messageIcon{
    display: none;
  }

  .button {
    background-color: #333333;
    color: white;
    border-radius: 6px;
    padding: 1rem 2rem;
    color: #fff;
    font-weight: bold;
    align-items: center;
    width: 230px;
    height: 56px;
    font-size: 0.9rem;
    transition: 0.5s ease;
    cursor: pointer;
    text-transform: capitalize;
    position: absolute;
    right: 0;


    background: linear-gradient(180deg, #FF0835 0%, #960822 100%);
    border-width: 1px, 1px, 0px, 1px;
    border-style: solid;
    border-color: #EB7777;
    
    &:hover {
      transform: translateY(-1px);
      box-shadow: inset 160px 0 0 0 #7c54d158;
    }
  }

  @media screen and (max-width: 968px) {
    .messageIcon {
      display: block;
      position: absolute;
      position: absolute;
      width: 25px;
      height: 40px;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 5px;
    }

    .inputContainer {
      gap: 0;

      img {
        position: relative;
        margin-right: 9px;
        left: 0;
      }

      input {
        padding-left: 12px;
        width: 100%;
        flex: 1;
      }
    }

    button{
      display: none;
    }
  }
}

@keyframes thumbsUpAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}


.thumb{
  animation: thumbsUpAnimation 1s ease-in-out infinite;
}


.chatContainer {
  margin-bottom: 50px;

  .top {
    display: flex;
    gap: 19px;
    align-items: flex-start;


    .userImage {
      width: 30px;
      border-radius: 50%;
      overflow: hidden;

      
      img {
        width: 100%;
      }
    }

    .item {
      .name {
        font-weight: 700;
        margin-bottom: 19px;
        justify-content: space-between;
        display: flex;
        gap: 10px;


         p:first-child {
          font-family: "Clash Display", "Cera Pro Bolder" !important;
          font-size: 18px;
         }

         p:last-child {
          // flex: 0.08;
          font-size: 12px;
         }
         
          @media screen and (max-width: 798px) {
            margin-bottom: 10px;
            p {
              font-size: 12px ;
            }

            p:first-child {
              font-family: "Clash Display", "Cera Pro Bolder" !important;
              font-size: 16px;
             }
    
          }
      }
    }

    .commnt {
      color: #686868;
      font-family: "Cera Pro";
      font-weight: 550;

      
      @media screen and (max-width: 768px) {
        font-size: 14px ;
      }
    }

    @media screen and (max-width: 768px) {
      gap: 11px;
    }

    

  }

  .reactContainer{
    color: #686868;
    font-weight: 700;
    margin-left: 60px;
    margin-top: 30px;
    display: flex;
    gap: 44px;
    align-items: center;
    position: relative;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3px;
    }


    .peopleContainer {
      display: flex;
      position: relative;
      
      width: 130px;

      @media screen and (max-width: 548px) {
        width: fit-content;
      }

       @media screen and (max-width: 358px) {
        display: none;
      }

    }

    .usersUrl {
      position: absolute;
      width: 30px;
      height: 30px;
      background: yellow; 
      border-radius: 50px;
      overflow: hidden;
      border: 1px solid black;

      img {
        width: 100%;
      }

      .usersUrl:first-child {
        top: 0px;
        left: 0;
        z-index: 3;
      }

       &:nth-child(2) {
        // top: 30px; /* Adjust the spacing between images */
        left: 20px; /* Adjust the spacing between images */
      }

       &:nth-child(3) {
        // top: 30px; /* Adjust the spacing between images */
        left: 30px; /* Adjust the spacing between images */
      }
       &:nth-child(3) {
        // top: 30px; /* Adjust the spacing between images */
        left: 40px; /* Adjust the spacing between images */
      }

       @media screen and (max-width: 548px) {
        width: 20px;
        height: 20px;
        

        .usersUrl:first-child {
          top: 0px;
          left: 0;
          z-index: 3;
        }

        &:nth-child(2) {
          // top: 30px; /* Adjust the spacing between images */
          left: 10px; /* Adjust the spacing between images */
        }

        &:nth-child(3) {
          // top: 30px; /* Adjust the spacing between images */
          left: 13px; /* Adjust the spacing between images */
        }
        &:nth-child(3) {
          // top: 30px; /* Adjust the spacing between images */
          left: 15px; /* Adjust the spacing between images */
        }

       }


    }
    .commentIcon{
      position: absolute;
      left: 90px;
      font-size: 20px;
      

      @media screen and (max-width: 548px) {
        width: 20px;
         left: 38px;
      }


    }



    @media screen and (max-width: 798px) {
      margin-left: 0;
      gap: 0;
      justify-content: space-between;

      .reportComment {
        display: none;
      }

      p {
        font-size: 12px;
      }
    }

  }
}

/* YourComponent.module.css */

.thumbsUpContainer {
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.3s ease-in-out;
}

.thumbsUpAnimating {
  animation: thumbsUpAnimation 1s ease-in-out infinite;
}

// @keyframes thumbsUpAnimation {
//   0%, 100% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-5px);
//   }
// }
