.community__card {
  background: #fff;
  border-radius: 6px;
  // color: #FFF;
  font-size: 0.85rem;
  font-weight: bold;

  &__header {
    background: linear-gradient(
        80.64deg,
        rgba(0, 0, 0, 0.15) 7.59%,
        #000000 200.48%
      ),
      url("../../assets/images/banner.png");
    background-position: center;
    background-size: cover;
    padding: 2rem 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      color: #fff;
      font-family: "Cera Pro Bolder";
    }
  }

  &__body {
    padding: 1rem 1rem;

    small {
      font-weight: "Cera Pro Bold";
    }

    p {
      // border-bottom: none;
      padding-bottom: 0.4rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }

    &--tabs {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
      grid-gap: 10px;
      padding: 0.4rem 0;

      small {
        font-size: 0.65rem;
      }

      &--item {
        padding: 0.7rem 0.5rem;
        border-radius: 10px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        &--visibility {
          @extend .community__card__body--tabs--item;
          background: #000;
        }

        &--members {
          @extend .community__card__body--tabs--item;
          background: #000;
        }

        &--status {
          @extend .community__card__body--tabs--item;
          background: #000;
        }

        &--details {
          @extend .community__card__body--tabs--item;
          background: green;
          cursor: pointer;
        }
        &--delete {
          @extend .community__card__body--tabs--item;
          background: #721c24;
          cursor: pointer;
        }
      }
    }
  }
}
