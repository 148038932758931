@import "@/styles/abstracts/variables";
@import "@/styles/base/typography";
@import "@/styles/abstracts/mixins";
@import "@/styles/abstracts/functions";

.onboarding {
  background-color: $col-primary;
  width: 100%;
  height: 100%;
  color: $col-white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 5.5rem;
  text-align: center;

  @include respond(big-tab) {
    display: none;
  }

  &__title {
    font-size: 50px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    font-family: "Cera Pro Bold", "san-serif";
    padding-top: 1rem;

    @include respond(max-laptop) {
      font-size: toRem(40px);
    }
  }

  p{
    padding-top: 1rem;
    line-height: 30px;
  }

  &__images {
    margin-top: toRem(19px);
  }
}
