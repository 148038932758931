.user__meta__info{
    
    &__intro{
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        img{
          border-radius: 50% !important;
        }

      
    h6 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        padding: 0px;
      }
  
      span {
        font-family: "Cera Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #999999;
        padding: 0px;
      }
    }
}