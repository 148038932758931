@import url('http://fonts.cdnfonts.com/css/clash-display');
@import '../../styles/abstracts/variables';



.games__container{
  font-family: 'Clash Display', 'Cera Pro Bold' !important;

  header {
    h1 {
      font-weight: 700;
      font-family: 'Clash Display', 'Cera Pro Bold' !important;
      font-size: 48px;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 14px;
    color: #333333;

    font-weight: 500;

    p {
      font-family: "Cera Pro";
    }



    &:hover {
      color:#A55BBF;
    }
  }
  

  &__card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    margin-top: 15px;
  }

  &__available{
    margin-top: 35px;

    h2 {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 15px;
      font-family: 'Clash Display', 'Cera Pro Bold' !important;
    }




  nav {
    display: flex;
    gap: 8px;

    @include tablet {
      flex-wrap: wrap;
    }

    .search__box {
      display: flex;
      align-items: center;
      border: 1px solid #dee5ed;
      border-radius: 6px;
      background-color: #ffffff;
      position: relative;
      width: 50%;

      @include tablet {
        width: 100%;
        margin-bottom: 0.7rem;
      }

      input {
        outline: none;
        padding: 10px;
        font-size: 14px;
        color: #666666;
        border: none;
        width: 80%;
        border-radius: 6px;
      }

      .search {
        background-color: #333333;
        font-size: 14px;
        color: #ffffff;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: grid;
        place-items: center;
        width: 20%;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: pointer;
      }
    }

    select {
      width: 240px;
      outline: none;
      border: 1px solid #dee5ed;
      border-radius: 6px;
      padding: 10px;

      @include tablet {
        width: 42%;
      }
    }

    .refresh,
    .export {
      width: 158px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      border: 1px solid #dee5ed;
      background-color: #ffffff;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;

      @include tablet {
        width: 25%;
      }
    }

    .export {
      background-color: rgba(51, 51, 51, 0.05);
      border: 1px solid #DEE5ED
    }
  }

  }



  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr); // Two items in a row for medium screens
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr; // One item in a row for small screens
    }
  }
}



.liveGame__container{
  margin-top: 15px;
  border-radius: 6px;
  overflow: hidden;
}

.leaderboard, .activities{
  &__container {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    background-color: white;

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  &__rank {
    width: 100%;

    &__header {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 5px;
      font-family: "Cera Pro Bold";
      font-size: 1rem;
      color: #333333;

      &--item1,
      &--item2,
      &--item3 {
        background: #F2F5F8;
        padding: 0.8rem 0px 0.8rem 40px;
      }
    }
    &__details {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;
      padding-bottom: 3rem;
    }
  }
}

.activities {
  margin-top: 14px;

  &__rank {
    width: 100%;

    &__header {
      grid-template-columns: repeat(5, 1fr);
      grid-gap:  0 ;

      &--item1,
      &--item2,
      &--item3 {
        background: #F3F3F3;
        padding: 0.8rem 30px 0.8rem 30px;
      }
    }
    &__details {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 15px;
    }
  }
}

// Media Queries
@media only screen and (max-width: 600px) {
  .leaderboard, .activities {
    &__rank {
      &__header {
        grid-template-columns: repeat(4, 1fr);
        &--item1,
        &--item2,
        &--item3 {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9rem;
          font-weight: bold;
        }
      }
      &__details {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

 .activities {
    &__rank {
      &__header {
        grid-template-columns: repeat(5, 1fr);
      }
      &__details {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
