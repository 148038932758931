@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.settings {
  h2 {
    font-family: "Clash Display", "Cera Pro Bold" !important;
    font-size: 2rem;
  }
  p {
    color: #333333;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0.5rem;
    font-size: 0.95rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #ccc;
    max-width: 150px;
    word-break: break-word;
  }

  &__card_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__card {
    background: #e2e2e2;
    border-radius: 20px;
    padding-top: 5.375rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 250px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #cccccc;
    }

    h2 {
      font-family: "Clash Display", "Cera Pro Bolder" !important;
      font-weight: bolder;
      padding-bottom: 1.25rem;
      color: #333333;
    }

    h5 {
      color: #333333;
      font-size: 0.9rem;
    }
  }
}

//Media Queries
@media only screen and (max-width: 600px) {
  .settings {
    &__card {
      flex: 0 0 auto;
      min-width: 90%;
      padding: 0.85rem 1.2rem !important;
      background: transparent;

      h2 {
        color: #fff;
        padding-top: 0.8rem;
        font-size: 1.2rem;
        padding-bottom: 0;
      }

      h5 {
        color: #fff;
        padding-bottom: 0.8rem;
        font-size: 0.72rem !important;
      }
    }
  }
}
