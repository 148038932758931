@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.blog {
  background: #fff;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: bold;
  border: 1px solid #fefefe;

  &__header {
    padding: 4rem 2rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      font-family: "Cera Pro Bolder";
    }
  }

  &__body {
    padding: 1rem;
    small {
      font-family: "Cera Pro Bold";
      color: #333;
    }
    h4 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 1rem;
    }
    p {
      color: #333;
      font-size: 0.82rem !important;
      line-height: 2;
      margin-bottom: 1.3rem;
    }

    &__counts {
      display: flex;
      gap: 10px;
      padding-top: 0.3rem;
      padding-bottom: 0.4rem;

      &--comments,
      &--likes {
        // background: #000;
        // padding: .4rem 1.5rem;
        border-radius: 50px;
        font-weight: bold;
        font-size: 0.7rem;
        font-family: "Cera Pro Bold";
        color: #333;
      }
    }

    &__tabs {
      display: flex;
      gap: 10px;
      justify-content: flex-end !important;

      &--read {
        background: #000;
        padding: 1rem 1.5rem;
        border-radius: 50px;
        font-weight: bold;
        font-size: 0.8rem;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      &--edit {
        @extend .blog__body__tabs--read;
        background: green;
      }
      &--delete {
        @extend .blog__body__tabs--read;
        background: #721c24;
      }
    }
  }
}
