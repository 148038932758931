@import "@/styles/abstracts/variables";
@import "@/styles/abstracts/mixins";
@import "@/styles/base/typography";

.input {
  width: 100%;
  height: 100%;
  position: relative;

  &__field {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;
  }

  &__field {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;
  }

  &__label {
    position: absolute;
    width: fit-content;
    left: toRem(23px);
    top: toRem(15px);
    color: $col-grey;
    background-color: $col-white;
    box-shadow: 0px 0px 0px 4px $col-white;

    padding: 0px;
    display: none;

    &[data-hidelabel="true"] {
      display: block;
      top: -10px;

      color: $col-grey;
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 23px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }
  }
  & input {
    width: 100%;
    height: 100%;
    color: $col-dark;
    background-color: $col-grey-light;
    padding: 20px 23px;

    border: 1px solid $col-grey;
    outline: none;
    transition: all 0.2s ease;
    @include border-radius(6px);

    &:focus {
      border: 1px solid $col-primary;
      background-color: $col-white;
    }

    &[data-error="true"] {
      border: 1px solid red;
      background-color: $col-white;
    }

    &[data-success="true"] {
      border: 1px solid green;
      background-color: $col-white;
    }

    &:focus + .input__label {
      top: -10px;

      color: $col-dark;
      width: fit-content;
      display: block;
      &[data-hidelabel="true"] {
        top: -10px;

        color: $col-grey;
        background-color: $col-white;
        width: fit-content;
      }
    }

    &:not(:focus) &:not(:placeholder-shown) + .input__label {
      top: -10px;

      color: $col-grey;
      background-color: $col-white;
      width: fit-content;
      display: block;

      &[data-hidelabel="true"] {
        top: -10px;

        color: $col-grey;
        background-color: $col-white;
        width: fit-content;
      }
    }
  }

  &__errors {
    color: red;
    margin-bottom: 0.6rem;

    //     @include body-three;
    margin-left: toRem(23px);
  }
}
