@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.single__adventure {
  &__header {
    // width: 100%;
    // height: 50%;
    // padding-top: 2rem;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    grid-gap: 5rem;

    &__body {
      padding-left: 2rem;
      padding-bottom: 2rem;

      &__btn {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 10px;
      }

      &--tabs {
        display: flex;
        gap: 20px;
        border-bottom: 1px solid #c3c3c3;
        margin-bottom: 2.4rem;
        padding-bottom: 1.5rem;
        span {
          background: #000;
          color: #fff;
          padding: 0.5rem 1rem;
          border-radius: 20px;
          font-size: 0.78rem;
          font-weight: bold;
        }
      }

      &--btn-danger {
        background: #721c24;
        padding: 1rem 2rem;
        border-radius: 10px;
        color: #fff;
        font-weight: bold;
        font-size: 0.9rem;
        cursor: pointer;
      }

      &--btn-success {
        @extend .single__adventure__header__body--btn-danger;
        background: green;
        margin-left: 2rem;
      }

      &--btn-primary {
        @extend .single__adventure__header__body--btn-danger;
        background: rgb(7, 7, 79);
        margin-left: 2rem;
      }

      p {
        background: #fcfcfc;
        padding: 2rem;
        border-radius: 10px;
        margin-bottom: 1.5rem;
        color: #000;
      }
    }
    h1 {
      font-family: "Clash Display", "Cera Pro Bold" !important;
      font-size: 2.2rem;
      padding-bottom: 1rem;
      color: #000;
      // text-align: center;
    }
    &__img {
      height: 150px;
      width: 150px;
      &--img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
      }
    }
  }

  &__result {
    max-width: 700px;
    margin: 0 auto;
    p {
      border-top: 1px solid #dee6ed;
      padding: 0.8rem 0;
      // color: #627081;
      font-size: 0.9rem;
      color: #333;
      font-weight: bold;
    }
  }
  &--back {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-family: "Cera Pro Bold";
    padding-bottom: 1rem;
  }
  &--img {
    max-width: 50px;
    height: auto;
    border-radius: 50px;
    // margin-top: 1rem;
  }
  h3 {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
    font-family: "Clash Display", "Cera Pro" !important;
  }
  p {
    line-height: 2;
  }
}
