.card {
  width: 100%;
  height: 90%;
  background: #FFF;
  border-radius: 6px;
&__body{
  padding: .7rem 1rem;
  &__buttons{
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  & h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__level {
    color: #333333;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0.5rem;
    font-size: 0.95rem;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
  }

  &__btn{
    background: #000;
    padding: .8rem 1rem;
    border-radius: 10px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    font-size: .9rem;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    width: 100%;
  }

  &__btn--feature{
    @extend .card__body__btn;
    background-color: green;
  }
}
&__image {
  height: 161px;
  width: 100%;
  margin-bottom: 10px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
}
