.leader {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 50px;
  font-size: 0.95rem;
  color: #333333;
  padding: 1.2rem 1rem;
  border-bottom: 1.17049px solid #dee6ed;
  font-weight: 400;


  &__user {
    display: flex;
    align-items: center;
    gap: 9px;
    color: #333333;

    &__avatar{
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background: whitesmoke;
      overflow: hidden;
    }
  }
}

// Media Queries
@media only screen and (max-width: 600px) {
  .leader {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    font-size: 0.9rem;
  }
}
