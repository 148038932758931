@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";
@import url("http://fonts.cdnfonts.com/css/clash-display");

.sidebar {
  background: $col-primary;
  color: #fff;
  height: 100vh;
  padding: 2rem 1.5rem;
  width: 270px;
  padding-right: 0;
  padding-top: 7rem;
  position: relative;
  z-index: 10000 !important;

  &__linkholder {
    margin-top: 0.5rem;
  }

  &__placeholder {
    @extend .sidebar-link--label;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &--links {
      padding-left: 1rem;
      padding-top: 1rem;
    }
  }

  // &__logout{
  //     position: absolute;
  //     background: $col-secondary;
  //     width: 85%;
  //     bottom: 2%;
  //     left: 0;
  //     margin: 0 1.5rem;
  //     border-radius: 10px;
  //     padding: .2rem 0;
  //     font-weight: bolder;
  // }

  .sidebar-link--label {
    color: #fff;
    font-size: 0.875rem;
    padding: 0.65rem 0rem;
    padding-left: 1.5rem;
    display: flex;
    align-items: center !important;
    font-family: "Cera Pro Bold";
    margin-bottom: 0.7rem;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none !important;
  }

  .sidebar-link--label-active {
    color: $col-primary;
    background: #fff;
    border-radius: 30px;
    padding-left: 1.5rem;
    margin-right: -2rem;
  }
}

// Media Queries
@media only screen and (max-width: 600px) {
  .sidebar {
    padding-top: 2rem;
    background: black;
  }
}

.showsidebar {
  background: red;
  height: 100%;
  position: relative;
  top: 0;
  bottom: 0;
}
