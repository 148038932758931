.task {
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 250px 250px 280px 200px 150px;
  gap: 15px;
  border-bottom: 1.17049px solid #dee6ed;
  position: relative;

  &__img {
    max-width: 50px;
    height: auto;
    border-radius: 50px !important;
  }

  &__actions {
    display: flex;
    &--action {
      cursor: pointer;
      padding-top: 0.6rem;
      padding-left: 1rem;
    }
  }

  &__first__element {
    display: flex;
    gap: 30px;
    align-items: flex-start;
  }

  span {
    font-size: 0.95rem;
  }

  &__active {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    width: 40%;
    height: 80%;
    border-radius: 5px;
    font-size: 0.85rem;
    color: #2fab5b;
  }

  &__inactive {
    @extend .task__active;
    color: #721c24;
    background: transparent;
    // color: #F8D7DA;
  }

  &__action--success {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    font-size: 0.8rem !important;
    background: #2fab5b;
    color: #fff;
    padding: 0.3rem 1rem !important;
    height: 100%;
    width: 100% !important;
    cursor: pointer;
  }

  &__action--danger {
    @extend .task__action--success;
    background: #721c24;
    // color: #F8D7DA;
  }
}
