@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.modal {
  display: flex;
  flex-direction: column;
  z-index: 10;
  // height: 100%;
  // width: 100%;

  &__bg_overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 150;
  }

  &__centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content_wrapper {
    // position: relative;
    width: auto;
    height: auto;
    background: #ffffff; // color: white;
    border-radius: 10px;
    z-index: 10;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 1.5em;
    position: relative;

    &:has([att="dark"]) {
      background: #000000;
    }
  }

  &__modalHeader {
    height: 50px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &__heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  &__content {
    // padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }

  &__closebtn {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1.5em;
    color: #666666;
    transition: all 0.25s ease;
    // box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    // padding: 0em;
    float: right;
    z-index: 90;
  }

  //   .cancelBtn {
  //     margin-top: 10px;
  //     cursor: pointer;
  //     font-weight: 500;
  //     padding: 11px 28px;
  //     border-radius: 12px;
  //     font-size: 0.8rem;
  //     border: none;
  //     color: #2c3e50;
  //     background: #fcfcfc;
  //     transition: all 0.25s ease;
  //   }

  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
  }
}

@include phone {
  .modal {
    &__bg_overlay {
      position: fixed;
      top: 0;
    }
    &__centered {
      width: 100%;
      padding: 0.5em;
    }
  }
}

@include landscape {
}
