@import "@/styles/abstracts/variables.scss";
@import "@/styles/base/typography.scss";
@import "@/styles/abstracts/mixins";

.button {
  width: 100%;
  height: 54px;
  //   @include button-txt;
  border-radius: 2px;
  &[data-loading="true"] {
    background-color: $col-primary;
    opacity: 0.5;
    @include flex-row;
    flex-wrap: nowrap;
  }

  &:disabled {
    //     background: $col-primary-2;
  }

  &:focus {
    background: $col-primary;
  }

  &-primary {
    background-color: $col-primary;
    color: $col-white;

    &:hover {
      // background: $col-primary-2;
    }
  }

  &-small {
    height: 40px;
  }

  &-curve {
    border-radius: toRem(8px);
  }

  &-disabled {
    //     background-color: $col-primary-2;
    color: $col-white;
    opacity: 0.3;
    cursor: not-allowed;

    &-text {
      width: fit-content;
      height: fit-content;
    }
  }

  &-alternate {
    background-color: $col-primary;
    color: $col-white;

    &:hover {
      // background: $col-primary-2;
    }
  }
  &-google {
    background-color: $col-white;
    //     color: $col-text;
    border: toRem(1px) solid $col-grey;
    @include flex-row;
    align-items: center;

    &:hover,
    &:focus {
      background: none;
    }

    &_icon {
      margin-right: toRem(10px);
      @include grid-center;
    }
  }

  &-plain {
    border: toRem(1px) solid $col-grey-light;

    &:hover,
    &:focus {
      background: none;
    }
  }

  &-color {
    &-primary {
      color: $col-primary;
    }
    &-secondary {
      // color: $col-secondary;
    }
    &-light {
      color: $col-white;
    }
    &-dark {
      color: $col-grey;
    }
    &-fail {
      // color: $col-fail;
    }
  }

  &-loader {
    display: inline-flex;
    margin-left: 20px;
    width: fit-content;
    height: fit-content;

    & span {
      display: block !important;
    }

    svg {
      width: 2.1em;
      transform-origin: center;
      animation: rotate 2s linear infinite;
    }

    circle {
      fill: none;
      stroke: $col-white;
      stroke-width: 2;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dashoffset: -125px;
      }
    }
  }
}
