$font: 'Cera Pro', sans-serif;
$mobile: 510px;
$tablet: 900px;

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

$col-white: #fff;
$col-dark: #000;
$col-grey: #898787;
$col-grey-2: #303030;
$col-grey-dark: #838383;
$col-grey-light: #fafafa;
$col-primary: #121212;
$col-primary-light: #1f1d67;

$col-heading-dark: #000;
$col-secondary: red;
$col-danger-color: green;
