@import url("http://fonts.cdnfonts.com/css/clash-display");
@import "../../styles/abstracts/variables";

.prompt {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 999;

  &__content {
    background: #fff;
    position: absolute;
    top: 22%;
    left: 34%;
    max-width: 650px;
    margin: 0 auto !important;
    border-radius: 10px;
    padding: 1.5rem 2.5rem;
    z-index: 999;
    // text-align: center;

    h4 {
      color: #000;
      margin-bottom: 1rem;
    }

    span {
      position: absolute;
      right: 20px;
      top: 25px;
      cursor: pointer;
    }

    &__detaills {
      &--user {
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: flex;
        gap: 20px;

        h5 {
          color: #000;
          font-size: 1rem;
        }
        h6 {
          padding-top: 0.2rem;
          color: #666;
          font-size: 0.85rem;
        }

        &--img {
          max-width: 50px;
          border-radius: 50px;
        }
      }

      &--btn {
        background: #808183;
        color: #fff;
        padding: 1rem 2rem;
        border-radius: 10px;
        font-family: "Cera Pro Bold";
        cursor: pointer;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      &--success {
        @extend .prompt__content__detaills--btn;
        background: rgb(12, 163, 12);
      }
    }
  }
}
