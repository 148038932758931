.comment {
  padding: 1em;
  margin-top: 1em;
  line-height: 10px;
  border-left: 2px solid #EDEDED;;


//   &::before {
//     content: "";
//     position: absolute;
//     left: 0;
//     top: 0; // If you want to set a smaller height and center it, change this value
//     z-index: 100;
//     width: 100%; /* Thickness */
//     height: 10px;
//     background: #555; /* Color */
//   }

  p {
    font-family: "Cera Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    padding-bottom: .3em;
    padding-top: .3em;
  }

  span {
    font-family: "Cera Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
    padding-right: 1em;
  }
}


.comments{
  border-bottom: 2px solid #ededed;
  padding-bottom: 1em;
  margin: 2em;


}