@import "../../styles/base/typography";

.communities__table__head {
  padding-top: 1rem;

  &__grid {
    width: fit-content;
    background: #666;
    padding: 1rem;
    color: #fff;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 250px 220px 100px 200px 300px;
    gap: 15px;
    div {
      font-family: "Cera Pro Bold";
    }
  }
}
