@import "../abstracts/functions";

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap");

@mixin menu-txt {
  font-size: toRem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(26px);
  letter-spacing: 0;
}

@mixin card-title {
  font-size: toRem(20px);
  font-weight: 400;
  line-height: toRem(28px);
  letter-spacing: 0em;
}
@mixin card-value {
  font-size: toRem(25px);
  font-weight: 700;
  line-height: toRem(38px);
  letter-spacing: 0em;
}

@mixin body {
  font-size: toRem(18px);
  font-weight: 400;
  line-height: toRem(28px);
  letter-spacing: 0em;
}


@font-face {
  font-family: "Cera Pro";
  // src: url("../../public/CeraFont/Cera\ Pro\ Light.otf");
  src: url("../../../public/CeraFont/Cera\ Pro\ Light.otf");
}

@font-face {
  font-family: "Cera Pro Bold";
  // src: url("../../public/CeraFont/Cera Pro Medium.otf");
  src: url("../../../public/CeraFont/Cera\ Pro\ Medium.otf");
}

@font-face {
  font-family: "Cera Pro Bolder";
  // src: url("../../public/CeraFont/Cera\ Pro\ Bold.ttf");
  src: url("../../../public/CeraFont/Cera Pro Bold.ttf");
}