.addProductComment {
  display: flex;
  // justify-content: space-between;
  align-items: center;
 gap: 20px;
  padding-top: 1em;

  img {
    border-radius: 50px;
    width: 20px;
  }


  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .2em;
    padding-right: 1em;
    background: #f2f5f8;
    border-radius: 20px;
    gap: 20px;

    &:hover {
      background: #ffffff;
      border: 1px solid #dee6ed;
      border-radius: 20px;
    }

    textarea {
      position: relative;
      width: 400px;
      height: 70px;
      border: none;
      background: transparent;

      &::-webkit-scrollbar {
        display: none;
      }

      &:active,
      &:focus,
      &:hover {
        background: transparent !important;
        border: none !important;
        // height: 70px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        // border-radius: 30px;
      }

    }

    svg {
      font-size: 1.5em;
    }
  }
}


@media only screen and (max-width: 600px){
  .addProductComment {
    &__message {
      textarea{
        width: 206px;
      }
    }
  }

}