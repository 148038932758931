@import url('http://fonts.cdnfonts.com/css/clash-display');
@import "../../styles/abstracts/variables";

.prompt{
    background: rgba(0,0,0,.8);
    position: fixed;
    height: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content{
        background: #fff;
        position: absolute;
        top: 30px;
        max-width: 650px;
        border-radius: 10px;
        padding: 1.5rem 2.5rem;
        z-index: 999;

        h4{
            font-family: "Clash Display",  "Cera Pro Bold" !important;
            color: #000;
            padding-bottom: 2rem;
        }

        span{
            position: absolute;
            right: 20px;
            top: 25px;
            cursor: pointer;
        }

        &__detaills{
            &--user{
                padding-top: 2rem;
                padding-bottom: 2rem;
                display: flex;
                gap: 20px;

                h5{
                    font-family: "Cera Pro Bold";
                    color: #000;
                    font-size: 1rem;
                }
                h6{
                    padding-top: .2rem;
                    color: #666;
                    font-size: .85rem;
                }

                &--img{
                    max-width: 50px;
                    border-radius: 50px;
                }
            }

            &--input{
                background: #FFF;
                border: 1px solid #ced1d6;
                border-radius: 10px;
                padding: 1rem 1.5rem;
                margin-bottom: 2rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 40px;

                input{
                    border: none;
                    color: #000;
                    box-shadow: none;
                    font-size: 1.2rem;
                    font-weight: bold;
                    font-family: "Clash Display", "Cera Pro Bolder" !important;
                }

                p{
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                h3{
                    font-size: 1rem;
                    font-family: "Cera Pro Bold";
                }
            }

            &--conversions{
                div{
                    display: flex;
                    justify-content: space-between;
                    p{
                        border-bottom: 0;
                    }
                }
            }
            &--btn{
                background: #808183;
                color: #fff;
                padding: 1rem 2rem;
                border-radius: 10px;
                font-family: "Cera Pro Bold";
                cursor: pointer;
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
            &--success{
                @extend .prompt__content__detaills--btn;
                background: rgb(12, 163, 12);
            }
        }
    }

    .titleContainer {
        color: #333333; 

        .title {
            font-weight: bold;
        }

        .name {
            color: #666666;
            font-weight: 400;
            margin-top: -20px;
        }
    }

    .prompt__contentInput {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        input {
            padding: 20px;
            flex: 1;
            border-radius: 10px;
            border: 1px solid #CCCCCC;
        }

        .exchange {
            color: rgba(0, 0, 0, 0.6);
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: Rotate(90deg);
            
        }

    }
}
