@import "../../styles/abstracts/variables";
@import url('http://fonts.cdnfonts.com/css/clash-display');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.navbar{
    padding: .6rem 4rem;
    background: #FFF;
    box-shadow: 0px 1px 7px -2px rgba(24, 39, 75, 0.06), 0px 2px 2px rgba(24, 39, 75, 0.06);
    position: fixed;
    right: 0;
    left: 0;
    width: 100vw !important;
    z-index: 100;

    &__content{
        display: flex;
        align-items: center;
        gap: 150px;
     
        &__logo{
            position: relative;
            // width: 15vw;
            a{
                position: relative;
                font-size: 3rem;
                font-weight: 700;
                font-family: "Clash Display", "Cera Pro Bolder" !important;
                color: #000;
            }

        }

        &__menu{
            padding-top: .3rem;
            display: flex;
            justify-content: space-between;
            width: 60vw !important;
            
            // a{
            //     font-family: "Montserrat";
            //     color: #666666;
            //     font-size: 1rem;
            // }
            //Left menu
        &__left{
            display: flex;
            gap: 50px;
            align-items: center;
            align-content: flex-start;
        }

        //Right menu
        &__right{
            display: flex;
            gap: 50px;
            align-items: center;
            align-content: flex-end !important;

            &__join--btn{
                background: #333333;
                border-radius: 30px;
                padding: .7rem 1.5rem;
                color: #FFF !important;
                font-weight: bold;
            }
        }
        }

        &__hamburger{
            font-size: 2rem;
            display: none;
        }
    }
}

.active{
    border-bottom: 5px solid #E01414;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    width: 50px;
}

//RESPONSIVENESS FOR SMALL TO MEDIUM SIZED PHONES 
@media only screen and (max-width: 1300px){
    .navbar{
        position: relative;
        &__content{

                &__menu{
                    background: #000;
                    position: absolute;
                    right: 0;
                    height: 100vh;
                    justify-content: flex-start;
                    flex-direction: column;
                    top: 68px;
                    gap: 30px;
                    align-items: center;
                    padding-top: 4rem;
                    display: none;
                    width: 100% !important;
    
                    a{
                        color: #FFF;
                    }
    
                    &__left{
                        flex-direction: column;
                        align-items: center;
                    }
    
                    &__right{
                        padding-top: 2.5rem;
                        flex-direction: column;
                        justify-items: flex-end;
                    }
                }

            &__hamburger{
                display: block !important;
                position: absolute;
                right: 5%;
                top: 20%;
                cursor: pointer;
            }
        }
    }
}

.showNav{
    .navbar__content__menu{
        display:  block !important;
    }
}

@media only screen and (max-width: 600px){
    .navbar{
        &__content{
            &__logo{
                a{
                    font-size: 2.5rem !important;
                }

                &__lines{
                    top: 20px;
                }
            }
        }
    }
}

// .hideNav{
//     display: block;
//     background: green !important;
// }

// Media Queries 
@media only screen and (max-width: 600px){
    .navbar{
        background: red;
        display: none;
    }
}