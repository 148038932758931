@import "../../styles/base/typography";

.configs__wrapper {
  margin-top: 2rem;

  &__pagination {
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    margin-top: 1rem;

    &__button {
      min-width: 100px;
      padding: 0.5rem;
      border-radius: 20px;
      background: #a4adc7;
      color: rgb(11, 15, 19);
      cursor: pointer;
      margin: 0.5rem;
      transition: all 0.2s ease-in-out;
      outline: none;
      border: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #859cd7;
        color: rgb(3, 5, 26);
      }
    }
  }

  &__container {
    margin-top: 1rem;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  &__grid {
    background: #666;
    padding: 1rem;
    color: #fff;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: grid;
    width: fit-content;
    grid-template-columns: 250px 250px 100px 100px;
    gap: 15px;
    div {
      font-family: "Cera Pro Bold";
    }
  }
}

.config {
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 250px 250px 100px 100px;
  gap: 15px;
  width: fit-content;
  border-bottom: 1.17049px solid #dee6ed;
  position: relative;

  &__img {
    max-width: 50px;
    height: auto;
    border-radius: 50px !important;
  }

  &__actions {
    display: flex;
    &--action {
      cursor: pointer;
      padding-top: 0.6rem;
      padding-left: 1rem;
    }
  }

  &__first__element {
    display: flex;
    gap: 30px;
    align-items: flex-start;
  }

  span {
    font-size: 0.95rem;
  }

  &__active {
    text-align: center;
    font-weight: bold;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    font-size: 0.85rem;
    background: #2fab5b;
    color: #fff;
  }

  &__action--success {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    font-size: 0.8rem !important;
    background: #2fab5b;
    color: #fff;
    padding: 0.6rem 1rem !important;
    height: 100%;
    width: 100% !important;
  }

  &__action--danger {
    @extend .config__action--success;
    background: #721c24;
    // color: #F8D7DA;
  }
}
