@import url('http://fonts.cdnfonts.com/css/clash-display');
@import "../../styles/abstracts/variables";

.single__user{

    &__header{
        padding: 6rem 0;
        background: linear-gradient(80.64deg, rgba(0, 0, 0, 0.15) 7.59%, #000000 200.48%), url("../../assets/images/banner.png");
        border-radius: 6px;
        margin-bottom: 1rem;
        h1{
            font-family: "Clash Display", "Cera Pro Bold" !important;
            font-size: 2.2rem;
            padding-top: .4rem;
            color: #FFF;
            text-align: center;
        }
    }

    &__result{
        max-width: 700px;
        margin: 0 auto;
        p{
            border-top: 1px solid #DEE6ED;
            padding: .8rem 0;
            // color: #627081;
            font-size: .9rem;
            color: #333;;
            font-weight: bold;
        }
    }
    &--back{
        cursor: pointer;
        font-weight: bold;
        display: flex;
        align-items: center;
        font-family: "Cera Pro Bold";
        padding-bottom: 1rem;
    }
    &--img{
        max-width: 50px;
        height: auto;
        border-radius: 50px;
        // margin-top: 1rem;
    }
    h3{
        padding-top: .5rem;
        padding-bottom: .3rem ;
        font-family: "Clash Display", "Cera Pro" !important;
    }
    p{
        line-height: 2;
    }
}
