@import url("http://fonts.cdnfonts.com/css/clash-display");

.create {
  &__header {
    font-family: "Clash Display", "Cera Pro Bold" !important;
    font-size: 3rem;
  }

  &__content {
    height: 700px;
    display: flex;
    gap: 150px;
    margin-top: 53px;
  }
  &__form {
    height: 100%;
    width: 700px;
    // flex: 1 1 600px;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    // flex: 1 1 100px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0rem;

    border-left: 3px solid #dee6ed;
    padding: 12px;
    height: fit-content;

    & span {
      color: #999999;
      cursor: pointer;
      margin-bottom: 26px;
      position: relative;

      &[data-active="true"] {
        font-weight: 500px;
        color: #333333;

        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: -15px;
          top: -12px;
          height: 48px;
          width: 3px;
          background-color: #e01414;
        }
      }
    }
  }

  &__back {
    cursor: pointer;
  }
}
