// Abstract
@import "./abstracts/functions";
@import "./abstracts/mixins";
@import "./abstracts/variables";

//Base
@import "./base/reset";
@import "./base/typography";

@import url('http://fonts.cdnfonts.com/css/clash-display');


body{
    background-color: #f5f5f5;
}

.pagination{
    // background: #fafafa;
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    gap: 30px;
    font-family: "Clash Display", "Cera Pro" !important;
}

.pagination .page-num{
    padding: 10rem .5rem;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 400;
}

// .pagination .page-num:hover{
//     background: #000;
//     color: #FFF;
// }

.pagination .active{
    background: #000;
    color: #FFF;
    padding: .5rem .8rem;
    border-radius: 5px;
    font-family: "Clash Display", "Cera Pro Bold" !important; 
    font-size: .9rem;
}