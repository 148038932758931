.community {
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 250px 220px 100px 200px 300px;
  gap: 15px;
  border-bottom: 1.17049px solid #dee6ed;

  span {
    font-size: 0.95rem;
  }

  &__btn--danger {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-weight: bold;
    text-align: center;
    width: 80%;
    border-radius: 5px;
    padding: 0.6rem 1rem;
    font-size: 0.85rem;
    background: #721c24;
    color: #f8d7da;
    cursor: pointer;
    margin-top: -0.5rem;
  }

  &__btn--details {
    @extend .community__btn--danger;
    background: #753ff5;
    color: #fff;
  }

  &__btn--success {
    @extend .community__btn--danger;
    background: #2fab5b;
    color: #fff;
  }
}
